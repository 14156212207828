@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Grand+Hotel&display=swap);
/* Roboto import */

/* Grand Hotel import */

body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "segoeprint";
  src: local("segoeprint"), url(../../static/media/segoeprint.74c70684.ttf) format("truetype");
}
/* Navbar ackground and layout */
#navbar {
    width: 100vw;
    height: 14vh;
    background: #FFDBE1;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    -webkit-align-items: center;
            align-items: center;
    list-style: none;
    padding: 0;
    /* position: fixed; */
    position: absolute;
    top: 0;
    z-index: 10;
}

/* Navbar list items */
#navbar li {
    width: 8%;
    text-align: center;
    font-size: 1vw;
    padding: 0.5vw 1vw;
}

/* Navbar list item text styles */
#navbar li p {
    width: 100%;
    text-align: center;
    font-size: 1vw;
    font-family: 'Roboto';
    font-weight: 500;
}

#navbar li p a {
    font-size: 1vw;
    font-family: 'Roboto';
    font-weight: 500;
    color: black;
    text-decoration: none; 
}

#navbar li p a:active {
    color: white;
    text-decoration: none; 
}

#navbar .normal:hover {
    background: #fff;
    transition: 0.4s ease-in;
    border-radius: 0.5vw;
    cursor: pointer;
}

#navbar .normal:hover a {
    color: black;
}

/* Center logo item styles */
#navbar .logo {
    width: 16%;
    -webkit-transform: translateY(-0.5vw);
            transform: translateY(-0.5vw);
    z-index: 2;
}

/* Center Logo Positioning */
#navbar .logo #logo {
    z-index: 1;
    position: absolute;
    left: 50%;
    top: 6.5vw;
    -webkit-transform: translate(-50%,-75%);
            transform: translate(-50%,-75%);
    width: 10vw;
    height: 10vw;
    padding: 0 0 0.5vw 0;
    background: #FFDBE1;
    border-radius: 50%;
}

/* Logo image styling */
#navbar .logo #logo img {
    width: 100%;
}
